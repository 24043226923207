import React, { useState } from 'react';
import axios from 'axios';
const telegram = window.Telegram.WebApp;
const TfanserUpload = () => {
  const [message, setMessage] = useState('');
  const [message2, setMessage2] = useState('');
  const [isUploading, setIsUploading] = useState(false);
  const [postdata, setPostData] = useState({
    post_name:"",
    post_video_url:"",
    post_thumbnail_url:"",
    is_premium:false,
    have_cost:false,
    post_price:0
  })
  const handlevideoFileChange = async (e) => {
    const file = e.target.files[0];

    // Validate file type
    if (file && file.type !== 'video/mp4') {
      setMessage('yalnızca .mp4 Dosyaları Geçerlidir!');
      e.target.value = ''; // Clear the input
      return;
    }

    if (file) {
      const formData = new FormData();
      formData.append('file', file);

      try {
        setIsUploading(true);
        setMessage('Dosya Kayd Ediliyor ...');
        const response = await axios.post('https://cdn.tfans.mebera.az/upload', formData, {
          headers: { 'Content-Type': 'multipart/form-data' },
        });

        setMessage(response.data.message);
        setTimeout(() => {
          setPostData(prevState => ({
            ...prevState,
            post_video_url : `https://cdn.tfans.mebera.az${response.data.filePath}`
          }))
        }, 1000);
        
      } catch (error) {
        setMessage('Dosya Kayd Edilirken Sorun Oluştu!');
        console.error(error);
      } finally {
        setIsUploading(false);
      }
    }
  };
  const handleImageFileChange = async (e) => {
    const file = e.target.files[0];
  
    // Validate file type
    if (file && !['image/jpeg', 'image/png', 'image/jpg'].includes(file.type)) {
      setMessage('Yalnızca .jpg, .jpeg veya .png dosyaları geçerlidir!');
      e.target.value = ''; // Clear the input
      return;
    }
  
    if (file) {
      const formData = new FormData();
      formData.append('file', file);
  
      try {
        setIsUploading(true);
        setMessage2('Dosya Kaydediliyor...');
        const response = await axios.post('https://cdn.tfans.mebera.az/upload', formData, {
          headers: { 'Content-Type': 'multipart/form-data' },
        });
  
        setMessage2(response.data.message);
        setPostData((prevState) => ({
          ...prevState,
          post_thumbnail_url: `https://cdn.tfans.mebera.az${response.data.filePath}`,
        }));
      } catch (error) {
        setMessage2('Dosya Kaydedilirken Sorun Oluştu!');
        console.error(error);
      } finally {
        setIsUploading(false);
      }
    }
  };
  
  
  const handleChangepost = (e) => {
    const { name, value } = e.target;
    setPostData(prevState => ({
      ...prevState,
      [name]: value
    }))
    console.log(postdata)
  }
  const addpost = async (e) => {
    e.preventDefault()
    try {
      const user = await telegram.initDataUnsafe.user
      const data = {...postdata,post_owner_id:user.id,is_premium:postdata.is_premium?(1):(0),have_cost:postdata.have_cost?(1):(0)}
      await fetch(`${process.env.REACT_APP_SERVER_URL}/addpost`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(data),
      })
      setPostData(prevState => ({
        ...prevState,
        post_name: "",
        post_video_url:"",
        post_thumbnail_url:"",
        is_premium:false,
        have_cost: false,
        post_price:0
      }))
    } catch (error) {
      console.log(error)
    }
    

  }
  const checkChange = (e)=>{
    const{name, checked} = e.target;
    setPostData(prevState => ({
      ...prevState,
      [name]: checked
    }))
    console.log(postdata)
  }
  return (
    <div id='post'>
      <form onSubmit={addpost} className='form-control mt-3 w-75 pt-3'>
        <div >
          <label htmlFor="postname" className='label-control mb-2'>Gönderi İsmi</label>
          <input type="text" id='postname' name='post_name' value={postdata.post_name} className='form-control mb-4' onChange={handleChangepost}  maxLength="10"/>
        </div>
        {postdata.post_video_url ? (
          <div className='upoadedfile' >
            <div className='close' onClick={()=>{setPostData(prevState=>({...prevState,post_video_url:""}))}}>X</div>
            <video src={postdata.post_video_url}></video>
          </div>
        ):(
          <div>
            <label htmlFor="postfile" className='label-control mb-2'>Gönderi Dosyası</label>
            <input type="file" id='postfile' name='postfile' className='form-control mb-4' accept="video/mp4" onChange={handlevideoFileChange} disabled={isUploading}   />
          </div>
        )}
        {message?(<div>{message}</div>):(<></>)}

        {postdata.post_thumbnail_url ? (
          <div className='upoadedfile' >
            <div className='close' onClick={()=>{setPostData(prevState=>({...prevState,post_thumbnail_url:""}))}}>X</div>
            <img src={postdata.post_thumbnail_url}/>
          </div>
        ):(
          <div>
            <label htmlFor="postfileimg" className='label-control mb-2'>Kapak Fotoğrafı</label>
            <input type="file" id='postfileimg' name='postfile' className='form-control mb-4' accept="image/jpeg,image/jpg,image/png" onChange={handleImageFileChange} disabled={isUploading} />
          </div>
        )}
        {message2?(<div>{message2}</div>):(<></>)}
        {postdata.have_cost?(<></>):(
          <div>
          <input type="checkbox" id='ispremium' className='check-control' checked={postdata.is_premium} name='is_premium' onChange={checkChange} />
          <label htmlFor="ispremium" className='label-control ms-2'> Yalnızca Premium Üyeler</label>
        </div>
        )}
        
        {postdata.is_premium?(<></>):(
          <div>
            <input type="checkbox" id='have_cost' className='check-control' checked={postdata.have_cost} name='have_cost' onChange={checkChange} />
            <label htmlFor="have_cost" className='label-control ms-2'> Ücretli</label>
          </div>
        )}
        {postdata.have_cost ? (
          <div >
            <label htmlFor="post_price" className='label-control mb-2'>Gönderi Ücreti</label>
            <input type="number" id='post_price' name='post_price' value={postdata.post_price} className='form-control mb-4' onChange={handleChangepost}/>
          </div>
        ):(<></>)}
        <button type='submit' className='btn btn-primary mt-4 mb-4'>Gönderi Paylaş</button>
      </form>
    </div>

  );
};

export default TfanserUpload;
