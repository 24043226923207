import React from 'react'
import { Link } from 'react-router-dom'

function TfanserHome() {
  return (
    <div>
      TfanserHome
      <Link to={"/upload"} className='btn btn-primary'>Upload Page</Link>
    </div>
  )
}

export default TfanserHome