import React, { useEffect, useState } from 'react'
import Loading from '../components/Loading';
const telegram = window.Telegram.WebApp;

function NormalUserHome() {
  const [haveuser, setHaveUser] = useState(false)
  const [tfanserData, setTfanserData] = useState(null)
  const [postsData, setPostsData] = useState(null)
  const getUserData = async (id) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/getuserdata`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ id: id }),
      })
      const result = await response.json()
      return result;
    } catch (error) {
      console.log(error)
    }
  }
  useEffect(() => {
    const checkUser = async () => {
      try {
        if (telegram.initDataUnsafe.start_param == undefined) {
          setHaveUser(false)
        } else {
          if (telegram.initDataUnsafe.start_param.includes("--")) {
            const invarry = await telegram.initDataUnsafe.start_param.split("--")
            const tfanserdata = await getUserData(invarry[1])
            if (tfanserdata == undefined) {
              setHaveUser(false)
            } else {
              if (tfanserdata.length == 0) {
                setHaveUser(false)
              } else {
                if (tfanserdata[0].role == 2) {
                  setHaveUser(true)
                  setTfanserData(tfanserdata[0])
                  const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/get-tfanser-posts`, {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({ id:invarry[1]}),
                  })
                  const result = await response.json()
                  setPostsData(result)
                } else {
                  setHaveUser(false)
                }
              }
            }
          } else {
            setHaveUser(false)
          }
        }
      } catch (error) {
        console.log(error)
      }
    }
    checkUser()
  }, [])
  return (
    <div id='normaluserhome'>
      <div className="viewmodal bg-dark bg-opacity-75">
        <div className="cls">X</div>
      </div>
      {haveuser ? (
        tfanserData ? (
          <div className='userprofile card'>
            <div className="profile">
              <div className='img'>
                <img src={tfanserData.photo_url} alt="" />
              </div>
              <div className='btns'>
                <a className="btn btn-primary" href={`https://t.me/${tfanserData.username}`}>{tfanserData.firstname} Özel Söhbet</a>
                <a className="btn btn-warning mt-2" href={`https://t.me/${tfanserData.username}`}>{tfanserData.firstname} Premium</a>
              </div>
            </div>
            <div className='posts'>
              {postsData?(
                postsData.map((post,index) => {
                  if(post.is_premium == 0 && post.have_cost == 0){
                    return(
                    <div className='postfree shadow' key={index} >
                      <div className='hidelayer'>
                        <img src="icons/play.svg" alt="" />
                      </div>
                      <img src={post.post_thumbnail_url} alt="" />
                    </div>
                    )
                  }
                })
              ):(<></>)}
              {postsData?(
                postsData.map((post,index) => {
                  if(post.is_premium == 0 && post.have_cost == 1){
                    return(
                    <div className='posthavecost shadow ' key={index}>
                      <div className='hidelayer bg-dark bg-opacity-25'>
                        <div className="imgs">
                          <img src="icons/star2.svg" alt="" />
                          <img src="icons/play.svg" alt="" />
                        </div>
                        <div className='mt-1'>Ücretli Video</div>
                      </div>
                      <img src={post.post_thumbnail_url} alt="" />
                    </div>
                    )
                  }
                })
              ):(<></>)}
              {postsData?(
                postsData.map((post,index) => {
                  if(post.is_premium == 1 && post.have_cost == 0){
                    return(
                    <div className='postpremium shadow ' key={index}>
                      <div className='hidelayer bg-dark bg-opacity-25'>
                        <div className="imgs">
                          <img src="icons/premium2.svg" alt="" />
                          <img src="icons/play.svg" alt="" />
                        </div>
                        <div className='mt-1'>Premium Video</div>
                      </div>
                      <img src={post.post_thumbnail_url} alt="" />
                    </div>
                    )
                  }
                })
              ):(<></>)}
              
            </div>
          </div>
        ):(
          <div>Kullanıcı Bulunamadı!</div>
        )
      ):(<Loading/>)}
    </div>
  )
}

export default NormalUserHome