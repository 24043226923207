import { useEffect, useState } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Loading from "./components/Loading";
import Blocked from "./components/Blocked";
import SuperHome from "./pages/SuperHome";
import TfanserHome from "./pages/TfanserHome";
import NormalUserHome from "./pages/NormalUserHome";
import UnKnownPlatform from "./components/UnKnownPlatform";
import TfanserUpload from "./pages/TfanserUpload";
const telegram = window.Telegram.WebApp;
function App() {
  const [userInitData, setUserInitData] = useState(null)
  const [userBaseData, setUserBaseData] = useState(null)
  const [version, setVersion] = useState("")
  const getUserData = async (id) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/getuserdata`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ id: id }),
      })
      const result = await response.json()
      return result;
    } catch (error) {
      console.log(error)
    }
  }
  const addReferral = async (data) => {
    try {
      await fetch(`${process.env.REACT_APP_SERVER_URL}/addrefferal`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(data),
      })
    } catch (error) {
      console.log(error)
    }
  }
  useEffect(() => {
    telegram.ready()
    telegram.expand()
    
    setVersion(telegram.platform)
    const userİnfo = async () => {
      try {
        const user = await telegram.initDataUnsafe.user
        setUserInitData(user)
        const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/getuserdata`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ id: user.id }),
        })
        const result = await response.json()
        if (result.length == 0) {
          const addUser = async () => {
            try {
              if (telegram.initDataUnsafe.start_param == undefined) {
                const data = { id: user.id, password: user.id, role: 3, is_premium: 0, is_verified: 0, invited_from: 0, username: user.username || "nousername", firstname: user.first_name || "nofirstname", lastname: user.last_name || "nolastname", inv_from: "no from", inv_for: "no for",photo_url: user.photo_url || "no image url" }
                await fetch(`${process.env.REACT_APP_SERVER_URL}/add-user`, {
                  method: 'POST',
                  headers: { 'Content-Type': 'application/json' },
                  body: JSON.stringify(data),
                })
                const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/getuserdata`, {
                  method: 'POST',
                  headers: { 'Content-Type': 'application/json' },
                  body: JSON.stringify({ id: user.id }),
                })
                const result = await response.json()
                setUserBaseData(result[0])
              } else {
                if (telegram.initDataUnsafe.start_param.includes("--")) {
                  const invarry = await telegram.initDataUnsafe.start_param.split("--")
                  if (invarry[0] == "" || invarry[1] == "" || invarry[0] == invarry[1]) {
                    const data = { id: user.id, password: user.id, role: 3, is_premium: 0, is_verified: 0, invited_from: 0, username: user.username || "nousername", firstname: user.first_name || "nofirstname", lastname: user.last_name || "nolastname", inv_from: "no from", inv_for: "no for",photo_url: user.photo_url || "no image url" }
                    await fetch(`${process.env.REACT_APP_SERVER_URL}/add-user`, {
                      method: 'POST',
                      headers: { 'Content-Type': 'application/json' },
                      body: JSON.stringify(data),
                    })
                    const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/getuserdata`, {
                      method: 'POST',
                      headers: { 'Content-Type': 'application/json' },
                      body: JSON.stringify({ id: user.id }),
                    })
                    const result = await response.json()
                    setUserBaseData(result[0])
                  } else {
                    // problem
                    const fromdata = await getUserData(invarry[0])
                    const fordata = await getUserData(invarry[1])
                    if (fromdata == undefined || fordata == undefined) {
                      const data = { id: user.id, password: user.id, role: 3, is_premium: 0, is_verified: 0, invited_from: 0, username: user.username || "nousername", firstname: user.first_name || "nofirstname", lastname: user.last_name || "nolastname", inv_from: "no from", inv_for: "no for",photo_url: user.photo_url || "no image url" }
                      await fetch(`${process.env.REACT_APP_SERVER_URL}/add-user`, {
                        method: 'POST',
                        headers: { 'Content-Type': 'application/json' },
                        body: JSON.stringify(data),
                      })
                      const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/getuserdata`, {
                        method: 'POST',
                        headers: { 'Content-Type': 'application/json' },
                        body: JSON.stringify({ id: user.id }),
                      })
                      const result = await response.json()
                      setUserBaseData(result[0])
                    } else {
                      if (fromdata.length == 0 || fordata.length == 0) {
                        const data = { id: user.id, password: user.id, role: 3, is_premium: 0, is_verified: 0, invited_from: 0, username: user.username || "nousername", firstname: user.first_name || "nofirstname", lastname: user.last_name || "nolastname", inv_from: "no from", inv_for: "no for",photo_url: user.photo_url || "no image url" }
                        await fetch(`${process.env.REACT_APP_SERVER_URL}/add-user`, {
                          method: 'POST',
                          headers: { 'Content-Type': 'application/json' },
                          body: JSON.stringify(data),
                        })
                        const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/getuserdata`, {
                          method: 'POST',
                          headers: { 'Content-Type': 'application/json' },
                          body: JSON.stringify({ id: user.id }),
                        })
                        const result = await response.json()
                        setUserBaseData(result[0])
                      } else {
                        const data = { id: user.id, password: user.id, role: 3, is_premium: 0, is_verified: 0, invited_from: 0, username: user.username || "nousername", firstname: user.first_name || "nofirstname", lastname: user.last_name || "nolastname", inv_from: invarry[0] || "no from", inv_for: invarry[1] || "no for",photo_url: user.photo_url || "no image url" }
                        await fetch(`${process.env.REACT_APP_SERVER_URL}/add-user`, {
                          method: 'POST',
                          headers: { 'Content-Type': 'application/json' },
                          body: JSON.stringify(data),
                        })
                        const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/getuserdata`, {
                          method: 'POST',
                          headers: { 'Content-Type': 'application/json' },
                          body: JSON.stringify({ id: user.id }),
                        })
                        const result = await response.json()
                        setUserBaseData(result[0])
                        const refdata = { inv_from: invarry[0], inv_for: invarry[1] }
                        await addReferral(refdata)
                      }
                    }

                  }
                } else {
                  const data = { id: user.id, password: user.id, role: 3, is_premium: 0, is_verified: 0, invited_from: 0, username: user.username || "nousername", firstname: user.first_name || "nofirstname", lastname: user.last_name || "nolastname", inv_from: "no from", inv_for: "no for",photo_url: user.photo_url || "no image url" }
                  await fetch(`${process.env.REACT_APP_SERVER_URL}/add-user`, {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify(data),
                  })
                  const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/getuserdata`, {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({ id: user.id }),
                  })
                  const result = await response.json()
                  setUserBaseData(result[0])
                }
              }

            } catch (error) {
              console.log(error)
            }
          }
          addUser()
        } else {
          setUserBaseData(result[0])
        }
      } catch (error) {
        console.log(error)
      }
    }
    userİnfo()
  }, [])
  return (
    <div className="App">
      {(userInitData == null || userInitData == undefined) || (userBaseData == null || userBaseData == undefined) ? (<Loading/>) : (<></>)}  
      {version == "android" || version == "ios" ? (
        userBaseData ? (
          userBaseData.role == 0 ? (
            <Blocked />
          ) : (
            userBaseData.role == 1 ? (
              <BrowserRouter>
                <Routes>
                  <Route path='/' element={<SuperHome />} />
                </Routes>
              </BrowserRouter>
            ) : (
              userBaseData.role == 2 ? (
                <BrowserRouter>
                  <Routes>
                    <Route path='/' element={<TfanserHome />} />
                    <Route path='/upload' element={<TfanserUpload />} />

                  </Routes>
                </BrowserRouter>
              ) : (
                userBaseData.role == 3 ? (
                  <BrowserRouter>
                    <Routes>
                      <Route path='/' element={<NormalUserHome />} />
                    </Routes>
                  </BrowserRouter>
                ) : (
                  <></>
                )
              )
            )
          )
        ) : (<></>)
      ) : (<UnKnownPlatform/>) //
      }
    </div>
  );
}
export default App;
